var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content
        ? _c("CoffeeCard", {
            attrs: {
              href: _vm.$customUrlScheme.page(
                "detail",
                "url=/coffee/detail/" + _vm.content.id
              ),
              src: _vm.content.image_url,
              title: _vm.content.name,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }