<template>
  <div>
    <CoffeeCard
      v-if="content"
      :href="
        $customUrlScheme.page(`detail`, `url=/coffee/detail/${content.id}`)
      "
      :src="content.image_url"
      :title="content.name" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: null
    }
  }
});
</script>
